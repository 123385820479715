.pairing_logo {
  width: 10%;
}
.pairing_card {
  width: 30%;
}

/* mobile screens */
@media only screen and (max-width: 767px) {
  .pairing_logo {
    width: 40%;
  }
  .pairing_card {
    width: 60%;
  }
}
